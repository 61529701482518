<template>
	<div class="tratament-etapa">
    <div>
      <h4 class="f-16">selectati tratament:</h4>
      <multiselect
        v-model="data.tratament"
        :multiple="false"
        :options="tratamente"
        placeholder="tratamente"
        track-by="name"
        label="name"
      />
    </div>

    <label for="cost">
      cost tratament:
      <input type="text"
             v-model="data.tratament.pret"
             id="cost"
             placeholder="0">
      lei
    </label>

    <div class="select-dinti">
      <h4>selectati dintii:</h4>
      <figure>
        <svg width="611" height="178" viewBox="0 0 611 178" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M597.963 121.248C598.215 116.022 596.891 117.881 591.788 118.434C577.295 119.942 562.299 106.023 568.915 130.242C570.679 136.724 574.019 142.955 579.249 148.181C582.399 151.397 595.379 159.637 588.826 149.085C586.873 145.869 585.55 142.905 584.478 139.438C584.1 138.131 582.84 130.644 586.432 134.915C591.473 140.995 592.292 144.864 600.357 149.939C602.058 150.995 609.431 153.658 608.926 150.442C608.8 149.487 603.76 144.864 602.562 143.106C598.026 136.423 597.585 128.634 597.963 121.248Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M567.466 99.943C563.244 101.048 563.055 105.269 563.874 108.133C565.197 112.605 564.882 114.615 570.679 114.565C577.61 114.515 601.428 124.062 600.546 107.681C600.231 101.601 597.963 102.857 592.733 101.702C584.037 99.7922 587.44 101.099 580.068 101.501C576.476 101.651 571.372 98.938 567.466 99.943Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M597.962 121.248C597.584 128.634 598.025 136.423 602.562 143.056C603.759 144.814 608.737 149.387 608.926 150.392C609.367 153.608 602.058 150.995 600.357 149.889C592.292 144.814 591.472 140.945 586.432 134.865C582.903 130.594 584.1 138.081 584.478 139.388C585.486 142.855 586.81 145.819 588.826 149.035C595.379 159.587 582.462 151.346 579.248 148.131C574.082 142.905 570.742 136.674 568.915 130.192C562.236 106.023 577.232 119.891 591.787 118.384C596.828 117.881 598.214 116.022 597.962 121.248ZM567.465 99.943C563.244 101.048 563.055 105.269 563.874 108.133C565.197 112.605 564.882 114.615 570.679 114.565C577.61 114.515 601.428 124.062 600.546 107.681C600.231 101.601 597.962 102.857 592.733 101.702C584.037 99.7923 587.44 101.099 580.067 101.501C576.476 101.651 571.372 98.938 567.465 99.943ZM591.85 152.452C591.535 150.191 587.692 144.362 586.621 141.247C581.076 125.72 596.072 157.627 608.548 152.452C612.896 150.643 606.973 146.171 605.398 144.362C599.286 137.428 598.53 126.273 599.79 118.133C601.428 107.882 605.776 100.747 588.133 99.8425C585.297 99.6918 582.525 99.8928 579.689 99.7923C576.35 99.6918 562.047 96.3754 562.109 106.123C562.109 107.983 565.197 116.022 565.701 119.188C567.528 130.745 570.049 144.362 583.092 152.603C585.297 154.06 592.481 157.678 591.85 152.452Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M547.806 115.52C543.836 117.831 541.19 118.685 536.212 118.082C530.793 117.429 527.328 117.781 522.539 117.379C515.923 116.776 515.356 114.515 519.893 132.704C521.342 138.684 523.862 148.08 527.391 153.356C528.336 154.763 532.684 159.888 535.141 157.929C538.04 155.617 529.596 143.005 531.612 137.93C533.125 134.111 537.094 140.543 537.787 141.548C539.615 144.362 547.113 157.024 551.02 157.175C556.06 157.376 548.058 141.297 547.617 139.186C545.601 130.242 548.751 123.559 547.806 115.52Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M518.569 98.0334C512.016 100.194 512.52 113.56 519.514 115.821C524.681 117.479 528.021 115.168 532.936 116.173C537.724 117.178 541.442 118.333 545.979 115.469C552.217 111.5 551.587 99.2896 542.45 99.0383C536.716 98.8876 534.322 102.706 529.848 101.249C526.698 100.244 522.665 96.7269 518.569 98.0334Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M547.806 115.52C548.814 123.559 545.601 130.242 547.554 139.136C547.995 141.196 556.06 157.326 550.956 157.125C547.05 156.974 539.615 144.362 537.724 141.498C537.094 140.493 533.061 134.061 531.549 137.88C529.533 142.955 537.976 155.567 535.078 157.879C532.62 159.838 528.273 154.713 527.327 153.306C523.799 148.03 521.278 138.634 519.829 132.654C515.293 114.464 515.86 116.726 522.476 117.329C527.264 117.731 530.73 117.379 536.149 118.032C541.19 118.685 543.836 117.831 547.806 115.52ZM518.569 98.0334C512.016 100.194 512.52 113.56 519.514 115.821C524.681 117.479 528.021 115.168 532.935 116.173C537.724 117.178 541.442 118.334 545.979 115.469C552.217 111.5 551.586 99.2896 542.45 99.0384C536.716 98.8876 534.322 102.706 529.848 101.249C526.697 100.244 522.665 96.727 518.569 98.0334ZM513.087 110.193C513.78 114.213 515.545 119.439 516.427 123.811C517.372 128.333 518.38 132.855 519.64 137.327C521.215 142.905 525.059 157.376 532.872 159.235C542.765 161.597 530.919 142.251 533.187 139.237C534.637 137.327 542.387 152.603 547.239 156.572C552.154 160.542 554.926 157.024 553.981 152.603C553.162 148.633 550.389 144.563 549.318 140.392C546.483 129.891 549.885 121.147 550.893 110.947C552.595 92.6067 536.023 99.9931 531.36 99.6916C526.508 99.3901 522.917 95.722 517.498 97.9832C512.709 99.8926 512.457 106.525 513.087 110.193Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M470.807 118.736C472.131 127.73 469.925 135.368 471.248 143.759C472.005 148.533 476.226 160.09 482.527 161.949C486.938 163.205 484.481 155.969 483.977 154.763C478.117 140.995 485.111 135.719 489.584 141.749C490.341 142.804 506.03 170.491 509.874 162.803C510.567 161.446 504.959 144.412 504.455 140.794C502.439 126.022 507.542 120.293 499.036 120.394C492.924 120.444 490.215 119.439 484.733 118.937C480.448 118.535 475.344 118.987 470.807 118.736Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M470.808 98.134C462.931 100.847 466.523 116.273 473.895 117.58C480.133 118.685 481.645 116.525 489.585 118.585C493.302 119.54 502.124 120.193 504.644 117.329C506.975 114.666 507.795 107.932 507.102 104.767C506.03 99.8927 501.746 102.405 496.201 99.8424C489.522 96.7773 488.261 99.2394 483.158 100.496C479.566 101.35 476.604 96.1743 470.808 98.134Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M470.808 118.735C475.344 118.987 480.385 118.534 484.733 118.936C490.215 119.439 492.987 120.394 499.036 120.394C507.606 120.343 502.439 126.021 504.455 140.794C504.959 144.412 510.567 161.446 509.874 162.803C506.03 170.491 490.341 142.804 489.585 141.749C485.111 135.669 478.117 140.995 483.977 154.763C484.481 155.969 486.938 163.255 482.527 161.948C476.289 160.14 472.005 148.532 471.249 143.759C469.988 135.367 472.194 127.73 470.808 118.735ZM470.808 98.1338C462.931 100.847 466.523 116.273 473.895 117.58C480.133 118.685 481.645 116.524 489.585 118.585C493.302 119.539 502.124 120.193 504.644 117.328C506.976 114.665 507.795 107.932 507.102 104.766C506.03 99.8924 501.746 102.405 496.201 99.8422C489.522 96.7771 488.261 99.2392 483.158 100.495C479.566 101.35 476.605 96.1741 470.808 98.1338ZM469.799 121.75C470.556 127.227 469.547 132.403 469.547 137.83C469.547 144.462 472.95 160.24 482.149 162.803C492.735 165.717 477.613 141.146 486.056 141.196C489.711 141.246 494.247 154.864 502.25 161.798C505.526 164.612 511.512 167.425 511.386 161.195C511.323 157.677 508.173 151.296 507.165 147.326C500.359 120.343 514.978 105.972 504.266 100.998C500.864 99.39 492.546 97.5308 488.703 97.8323C475.218 98.988 478.999 96.9278 473.139 97.1791C466.649 97.4805 464.57 103.259 465.074 107.681C465.515 111.349 469.106 116.926 469.799 121.75Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M439.302 123.71L438.357 123.208L437.475 122.956L436.719 122.605L436.088 122.102L435.71 121.449C434.765 121.298 434.954 121.499 435.269 120.796L434.45 120.695L434.513 121.449L435.332 123.61C435.899 131.85 435.962 141.046 437.601 149.085C438.231 152.15 442.326 168.28 447.304 167.627C449.699 165.818 448.691 159.135 448.754 156.421C448.817 152.201 449.321 147.829 450.077 143.659C450.896 138.935 454.361 124.866 453.668 120.997C452.156 120.444 449.258 127.328 439.302 123.71Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M442.327 99.0382C440.94 99.3899 434.702 102.606 433.757 103.46C428.905 108.183 434.828 125.619 446.233 123.459C452.849 122.202 455.937 113.861 455.874 109.138C455.811 105.42 454.866 104.013 451.148 102.003C448.88 100.797 445.162 98.3347 442.327 99.0382Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M435.332 123.559L434.513 121.399L434.45 120.645C434.513 120.494 434.639 120.293 434.702 120.243C434.765 120.243 434.954 120.343 434.954 120.293C435.017 120.092 435.143 120.595 435.269 120.745L435.71 121.399L436.089 122.052L436.719 122.554L437.475 122.906L438.357 123.157C439.554 123.006 439.302 122.805 439.302 123.66C449.195 127.278 452.156 120.394 453.605 120.946C454.299 124.815 450.896 138.835 450.014 143.608C449.258 147.779 448.817 152.15 448.691 156.371C448.628 159.034 449.636 165.767 447.241 167.576C442.264 168.179 438.168 152.05 437.538 149.035C435.963 140.995 435.9 131.8 435.332 123.559ZM442.327 99.0383C440.94 99.39 434.702 102.606 433.757 103.46C428.905 108.183 434.828 125.619 446.233 123.459C452.849 122.202 455.937 113.861 455.874 109.138C455.811 105.42 454.866 104.013 451.148 102.003C448.88 100.797 445.162 98.3348 442.327 99.0383ZM431.048 111.198C432.056 118.384 434.135 123.961 434.576 131.75C434.954 139.036 435.143 145.668 437.097 152.904C438.042 156.321 441.696 167.827 446.17 168.983C451.715 170.39 450.14 153.808 450.455 150.944L457.134 109.691C457.26 103.159 446.17 92.2548 433.82 102.053C430.67 104.465 430.607 107.882 431.048 111.198Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M437.601 122.956L439.491 123.76L439.995 123.107L437.853 122.605L437.601 122.956Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M402.441 121.901C402.945 127.83 408.616 166.923 417.186 166.722C420.588 164.562 417.69 148.482 417.627 144.312C417.564 141.096 419.265 121.7 418.824 120.796C416.744 120.243 414.539 124.765 405.592 121.7L404.836 121.248L404.079 120.846L403.449 120.344L403.008 119.69C402.126 119.54 402.252 119.791 402.567 119.037L401.811 119.087L401.874 119.69L402.441 121.901Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M409.75 98.6867C407.923 99.1892 404.205 102.104 402.693 103.31C399.543 105.772 399.228 107.329 399.984 110.796C403.575 127.78 422.415 123.409 422.1 108.686C421.974 103.209 411.263 98.2345 409.75 98.6867Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M401.874 119.74L401.811 119.138C401.874 118.987 402.252 118.886 402.315 118.685C402.378 118.484 402.504 118.937 402.567 119.087L403.008 119.74L403.449 120.394L404.079 120.896L404.835 121.298L405.591 121.75C414.539 124.816 416.744 120.293 418.824 120.846C419.265 121.7 417.563 141.146 417.626 144.362C417.689 148.482 420.588 164.612 417.185 166.772C408.616 166.973 402.945 127.881 402.441 121.951L401.874 119.74ZM409.75 98.6867C407.923 99.1892 404.205 102.104 402.693 103.309C399.542 105.772 399.227 107.329 399.984 110.796C403.575 127.78 422.415 123.409 422.1 108.686C421.974 103.209 411.262 98.2344 409.75 98.6867ZM398.408 110.193C398.66 112.756 400.236 116.525 400.614 119.59C401.118 123.057 401.496 126.574 402.063 130.041C403.071 136.674 404.52 143.307 406.348 149.789C407.482 153.859 410.317 164.16 414.035 166.873C422.73 173.254 419.58 154.411 419.454 152.402C418.824 145.216 418.95 137.528 419.706 130.343C420.399 123.66 423.108 111.801 423.234 110.093C423.801 102.204 412.901 97.732 410.569 97.6813C407.482 97.6315 397.652 102.204 398.408 110.193Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M379.19 127.277C372.322 129.89 367.218 122.856 366.903 125.268C366.021 133.408 369.676 163.657 376.67 173.003C377.363 173.958 380.261 177.224 381.963 176.37C383.349 175.666 382.278 170.993 382.089 169.737C380.639 157.677 381.648 146.02 383.034 133.96C384.672 120.896 383.727 125.519 379.19 127.277Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M372.763 97.4807C355.435 102.958 365.958 129.941 378.119 126.524C384.546 124.715 387.256 114.565 387.004 109.842C386.689 105.52 378.371 95.6718 372.763 97.4807Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M366.903 125.268C367.155 122.856 372.322 129.891 379.19 127.278C383.79 125.519 384.672 120.896 383.16 133.961C381.71 145.97 380.765 157.627 382.215 169.737C382.341 171.043 383.475 175.717 382.089 176.37C380.387 177.224 377.489 173.958 376.796 173.003C369.675 163.657 366.021 133.408 366.903 125.268ZM372.763 97.4807C355.435 102.958 365.958 129.941 378.119 126.524C384.546 124.715 387.255 114.565 387.003 109.842C386.688 105.52 378.371 95.6718 372.763 97.4807ZM362.24 112.203C362.618 115.218 364.698 119.188 365.265 122.806C365.895 126.775 365.958 131.046 366.273 135.066C366.903 142.955 367.659 150.894 369.486 158.632C374.275 178.983 385.176 182.5 383.853 171.546C382.152 157.678 383.349 135.619 386.058 122.152C386.814 118.334 388.327 114.716 388.201 110.796C388.075 105.721 381.773 96.4255 374.275 96.5252C365.391 96.5763 361.421 106.274 362.24 112.203Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M337.099 119.238C337.099 124.514 337.225 166.32 343.4 166.672C344.724 165.164 345.228 146.774 345.543 143.206C345.921 139.639 348.63 122.655 347.685 120.595C345.795 120.193 345.291 122.504 342.14 122.303C339.809 122.152 338.675 120.444 337.099 119.238Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M335.839 96.0738C332.752 100.898 336.595 130.092 347.181 118.334C350.017 115.218 350.521 106.073 350.647 102.104C350.71 99.3901 351.151 96.325 346.929 95.8226C344.85 95.6718 337.919 95.7723 335.839 96.0738Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M337.1 119.238C338.738 120.444 339.872 122.152 342.14 122.303C345.228 122.504 345.795 120.193 347.685 120.595C348.631 122.655 345.921 139.639 345.543 143.206C345.165 146.774 344.724 165.164 343.401 166.672C337.163 166.32 337.037 124.514 337.1 119.238ZM335.839 96.0738C332.752 100.898 336.596 130.092 347.181 118.334C350.017 115.218 350.521 106.073 350.647 102.103C350.71 99.3901 351.151 96.325 346.929 95.8225C344.85 95.6718 337.919 95.7723 335.839 96.0738ZM351.844 106.174C352.726 97.1792 351.655 93.5111 338.927 94.6166C330.547 95.3703 333.508 107.279 334.579 112.002C337.415 124.464 335.587 140.493 337.478 153.909C337.604 154.763 340.565 172.852 344.724 167.024C346.047 165.164 346.362 149.889 346.677 146.422C347.748 132.855 350.521 119.439 351.844 106.174Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M318.385 160.743C320.402 159.386 320.276 143.256 320.591 139.89C320.843 136.724 322.922 121.6 322.04 119.791C320.339 119.439 319.267 120.695 316.369 120.193C314.29 119.841 313.407 118.635 312.21 118.786C310.572 122.454 313.029 159.687 318.385 160.743Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M310.761 95.722C307.737 97.3802 310.32 115.469 314.353 118.183C317.062 119.992 320.465 119.489 322.103 116.977C323.93 114.163 325.19 104.515 325.442 101.099C325.632 98.2344 326.073 95.8225 321.725 95.3703C320.15 95.2196 311.832 95.1191 310.761 95.722Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M318.385 160.743C313.029 159.738 310.572 122.504 312.147 118.786C313.408 118.635 314.227 119.841 316.306 120.193C319.268 120.645 320.276 119.439 321.977 119.791C322.859 121.6 320.78 136.724 320.528 139.89C320.276 143.206 320.465 159.336 318.385 160.743ZM310.761 95.722C307.737 97.3802 310.32 115.469 314.353 118.183C317.062 119.992 320.465 119.489 322.103 116.977C323.93 114.163 325.19 104.515 325.443 101.099C325.632 98.2344 326.073 95.8225 321.725 95.3703C320.15 95.2195 311.832 95.119 310.761 95.722ZM310.509 121.75C310.698 130.041 311.265 152.603 315.298 159.587C317.062 162.652 320.024 162.602 320.906 159.185C321.536 156.723 321.284 152.1 321.347 149.387C321.851 137.327 322.985 122.856 325.38 110.947C326.199 106.827 329.538 95.8727 322.67 94.4658C320.276 93.9633 312.84 93.8126 310.635 94.6668C307.485 95.923 308.178 100.244 308.304 102.606C308.619 109.239 310.32 114.716 310.509 121.75Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M288.203 118.736C288.77 125.77 289.715 132.805 290.282 139.89C290.535 143.206 290.471 159.386 292.488 160.743C297.844 159.738 300.301 122.504 298.726 118.786C296.773 118.535 294 122.504 288.203 118.736Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M287.636 95.7221C282.658 97.732 287.258 116.072 289.842 118.133C292.299 120.092 296.017 119.238 297.781 117.077C300.175 114.063 301.183 105.269 301.372 101.601C301.499 98.9379 302.507 95.7723 298.537 95.3703C296.647 95.1693 289.085 95.1693 287.636 95.7221Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M288.203 118.736C294 122.504 296.773 118.585 298.663 118.786C300.301 122.504 297.844 159.688 292.425 160.743C290.409 159.386 290.535 143.256 290.22 139.89C289.716 132.805 288.77 125.77 288.203 118.736ZM287.636 95.7221C282.658 97.732 287.258 116.072 289.842 118.133C292.299 120.092 296.017 119.238 297.781 117.077C300.175 114.063 301.183 105.269 301.372 101.601C301.499 98.9379 302.507 95.7723 298.537 95.3703C296.647 95.1693 289.085 95.1693 287.636 95.7221ZM284.171 102.154C284.675 108.334 286.25 114.515 287.006 120.796L290.283 159.989C291.795 163.506 294.819 161.396 296.017 158.884C301.436 147.327 299.167 121.198 301.309 111.349C302.129 107.631 305.216 95.8728 299.356 94.4156C296.962 93.8126 289.59 93.8629 287.258 94.6669C283.604 95.9231 283.982 99.4906 284.171 102.154Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M263.251 120.595C262.306 122.604 265.015 139.639 265.394 143.206C265.709 146.774 266.276 165.164 267.536 166.672C272.955 166.37 274.908 123.861 273.459 119.489C272.388 120.042 271.064 122.203 268.733 122.353C265.646 122.504 265.141 120.193 263.251 120.595Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M275.16 96.2244C272.955 95.6717 266.402 95.6215 264.007 95.923C259.912 96.4254 260.164 99.5408 260.227 102.154C260.353 106.023 260.92 115.369 263.692 118.384C274.341 129.991 278.185 100.646 275.16 96.2244Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M263.251 120.595C265.142 120.193 265.646 122.504 268.796 122.303C271.128 122.152 272.451 119.992 273.522 119.439C274.971 123.81 272.955 166.32 267.599 166.622C266.276 165.114 265.772 146.723 265.457 143.156C265.016 139.639 262.306 122.605 263.251 120.595ZM275.16 96.2244C272.955 95.6717 266.402 95.6215 264.008 95.9229C259.912 96.4254 260.164 99.5408 260.227 102.154C260.353 106.023 260.92 115.369 263.693 118.384C274.341 129.991 278.185 100.646 275.16 96.2244ZM259.03 105.169C259.786 115.67 263.062 132.453 264.26 145.568C264.638 149.588 264.764 163.858 265.898 166.421C267.536 170.139 270.309 166.571 271.065 164.662C272.199 161.898 272.829 158.23 273.27 155.316C275.412 141.648 274.341 121.147 276.169 112.907C277.114 108.686 280.264 95.9732 273.144 94.8175C261.109 92.7573 258.4 96.5259 259.03 105.169Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M226.768 124.263C228.28 139.689 230.549 152.2 228.973 167.777C228.784 169.536 226.075 179.435 231.683 175.515C237.606 171.295 240.252 158.632 241.26 152.552C241.954 148.281 244.6 127.931 243.592 125.016C240.945 123.71 239.244 132.554 226.768 124.263Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M234.896 97.5812C227.524 99.9931 223.302 107.279 224.122 113.208C224.815 118.183 228.532 128.484 237.102 126.624C244.096 125.117 252.035 109.239 244.285 101.249C242.332 99.2897 238.11 96.526 234.896 97.5812Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M241.261 152.552C240.252 158.683 237.606 171.345 231.683 175.516C226.138 179.435 228.785 169.536 228.974 167.777C230.486 152.201 228.218 139.689 226.768 124.263C239.307 132.554 240.946 123.71 243.655 125.016C244.6 127.931 242.017 148.281 241.261 152.552ZM234.897 97.5812C227.524 99.9931 223.303 107.279 224.122 113.208C224.815 118.183 228.533 128.484 237.102 126.624C244.096 125.117 252.035 109.239 244.285 101.249C242.332 99.2896 238.11 96.526 234.897 97.5812ZM248.507 113.208C249.704 107.179 246.554 97.8827 237.984 96.526C230.864 95.4205 223.555 104.465 222.925 109.289C222.358 113.409 223.807 116.876 224.689 120.846C226.39 128.232 228.154 150.392 228.154 157.426C228.154 165.516 224.626 178.882 230.549 177.324C243.655 173.857 244.474 134.162 245.608 124.112C245.986 119.74 247.751 116.927 248.507 113.208Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M208.558 119.339C200.367 126.423 194.129 120.745 192.239 120.695C191.609 121.449 193.31 143.055 193.247 146.271C193.247 147.427 189.088 172.953 196.586 165.516C202.068 160.089 209.188 126.725 208.558 119.339Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M199.737 98.5359C198.855 98.7369 193.562 102.254 192.428 102.958C183.984 108.435 191.608 123.057 201.312 121.7C207.361 120.846 210.89 113.409 211.205 109.088C211.583 103.309 201.186 98.1842 199.737 98.5359Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M208.558 119.339C209.188 126.725 202.068 160.039 196.523 165.567C189.025 173.003 193.184 147.477 193.184 146.322C193.247 143.106 191.545 121.499 192.176 120.746C194.066 120.796 200.367 126.474 208.558 119.339ZM199.737 98.536C198.855 98.737 193.562 102.254 192.428 102.958C183.984 108.435 191.608 123.057 201.312 121.7C207.361 120.846 210.89 113.409 211.205 109.088C211.583 103.31 201.186 98.1842 199.737 98.536ZM212.339 111.198C213.41 105.621 208.369 98.8877 201.627 97.7823C196.271 96.8778 187.891 104.013 187.702 108.736C187.576 111.751 189.025 115.52 189.592 118.635C191.545 128.785 192.365 140.995 191.608 151.095C191.419 153.407 187.765 171.094 195.83 167.526C203.895 163.959 208.999 129.69 210.07 121.047C210.512 117.278 211.646 114.565 212.339 111.198Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M175.541 123.559L176.36 121.348L176.423 120.645L175.604 120.695C175.919 121.449 176.108 121.198 175.163 121.399L174.785 122.052L174.155 122.554L173.399 122.906L172.517 123.157L171.571 123.66C161.994 127.328 158.717 120.394 157.268 120.946C156.512 125.217 161.868 145.166 162.183 155.869C162.246 158.632 161.238 165.767 163.632 167.576C168.484 168.28 172.58 152.402 173.21 149.437C174.911 141.347 175.037 131.9 175.541 123.559Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M165.711 99.0886C164.325 99.4906 158.276 102.706 157.268 103.561C151.408 108.485 157.457 125.469 168.421 123.509C175.478 122.203 178.25 114.565 178.691 109.59C179.006 105.922 178.691 103.963 174.722 101.902C172.201 100.646 168.736 98.2344 165.711 99.0886Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M171.571 123.71C171.571 122.806 171.319 123.057 172.517 123.207L173.399 122.956L174.155 122.605L174.785 122.102L175.163 121.449L175.604 120.745C175.73 120.595 176.108 120.494 176.171 120.293C176.234 120.092 176.36 120.544 176.423 120.695L176.36 121.399L175.541 123.609C175.037 131.951 174.848 141.397 173.21 149.537C172.58 152.502 168.484 168.38 163.632 167.677C161.175 165.818 162.246 158.733 162.183 155.969C161.868 145.317 156.512 125.318 157.268 121.047C158.654 120.444 161.931 127.378 171.571 123.71ZM165.711 99.0885C164.325 99.4905 158.276 102.706 157.268 103.561C151.408 108.485 157.457 125.469 168.421 123.509C175.478 122.203 178.25 114.565 178.692 109.59C179.007 105.922 178.692 103.963 174.722 101.902C172.201 100.646 168.736 98.2343 165.711 99.0885ZM179.763 111.701C180.96 105.269 177.116 98.988 168.295 98.0836C161.994 97.4304 154.054 103.309 153.802 108.786C153.676 111.5 159.851 145.467 160.356 149.99C160.671 153.105 159.347 169.787 164.388 169.033C168.925 168.38 172.643 156.924 173.525 153.708C175.604 146.271 175.793 139.789 176.234 132.302C176.738 124.413 178.376 119.188 179.763 111.701Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M171.382 123.76L173.272 122.956L171.193 122.705L171.382 123.76Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M139.877 119.74C130.615 117.027 121.604 120.494 112.342 120.394C103.142 120.293 108.372 124.866 106.419 140.392C105.915 144.211 100.433 161.446 100.811 162.552C103.016 168.883 114.232 153.205 115.051 151.899C116.374 149.789 124.629 132.353 128.409 141.849C129.858 145.518 128.724 150.442 127.149 154.009C120.596 169.084 136.349 161.396 139.373 144.714C140.948 136.322 138.806 128.484 139.877 119.74Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M144.351 108.183C145.044 104.917 144.603 99.2896 139.877 98.0334C133.513 96.3752 131.433 101.651 127.464 100.345C122.108 98.5861 120.533 97.0284 114.421 99.993C109.002 102.606 104.78 99.4905 103.835 105.169C103.331 108.284 104.087 114.967 106.607 117.53C109.254 120.142 118.201 119.338 121.856 118.384C133.324 115.319 141.137 122.554 144.351 108.183Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M139.877 119.74C138.806 128.484 140.948 136.322 139.436 144.663C136.412 161.346 120.659 169.034 127.212 153.959C128.724 150.392 129.921 145.518 128.472 141.799C124.692 132.302 116.437 149.738 115.114 151.849C114.295 153.155 103.079 168.782 100.874 162.501C100.496 161.396 105.978 144.161 106.482 140.342C108.435 124.816 103.205 120.243 112.405 120.343C121.604 120.494 130.615 117.027 139.877 119.74ZM144.351 108.183C145.044 104.917 144.603 99.2896 139.877 98.0334C133.513 96.3753 131.434 101.651 127.464 100.345C122.108 98.5862 120.533 97.0285 114.421 99.9931C109.002 102.606 104.78 99.4906 103.835 105.169C103.331 108.284 104.087 114.967 106.608 117.53C109.254 120.142 118.202 119.338 121.856 118.384C133.324 115.319 141.137 122.554 144.351 108.183ZM145.611 108.686C146.43 104.465 145.044 98.0837 138.68 97.2797C134.773 96.7772 129.984 98.7369 123.179 97.9832C118.58 97.4807 111.144 99.0384 107.049 100.847C101.063 103.46 102.386 109.54 103.394 114.062C110.073 143.106 100.811 150.392 99.6135 160.491C98.9834 166.119 103.709 166.069 107.931 162.753C113.098 158.683 122.171 141.9 124.44 141.297C129.354 140.041 126.897 151.095 126.267 152.753C118.895 172.4 140.885 162.351 141.389 138.784C141.515 133.358 140.444 128.182 141.011 122.705C141.578 116.977 144.729 113.108 145.611 108.686Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M62.1219 115.721C63.5712 123.81 65.1464 131.9 63.1301 140.041C62.689 141.849 53.9936 158.783 60.4837 157.024C64.3273 155.969 71.2585 144.362 73.3378 141.146C79.6388 131.549 80.962 138.282 77.1814 149.186C76.5513 150.995 73.7159 156.673 75.9842 157.979C78.6307 159.486 82.7893 154.361 83.7345 152.904C88.1452 146.12 92.1149 129.438 93.6271 121.047C94.5723 115.721 92.3669 117.077 87.3891 117.329C74.9131 117.982 71.1954 119.539 62.1219 115.721Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M89.0907 97.8326C85.1841 98.4356 82.0336 102.053 78.1899 101.551C74.3463 101.048 71.1328 97.4306 65.4618 99.6416C58.9088 102.204 59.0978 114.163 67.8562 116.726C73.9682 118.535 76.0476 115.771 81.0254 115.872C85.1841 115.972 89.9729 117.58 93.6905 114.615C98.4163 110.947 98.6053 96.3754 89.0907 97.8326Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M60.4836 157.024C53.9935 158.783 62.689 141.85 63.13 140.041C65.1464 131.901 63.5081 123.811 62.1219 115.721C71.2584 119.54 74.913 117.982 87.326 117.379C92.3038 117.128 94.5722 115.771 93.564 121.097C92.0518 129.489 88.0821 146.171 83.6714 152.954C82.7262 154.412 78.5676 159.537 75.9211 158.029C73.6528 156.723 76.4882 151.045 77.1183 149.236C80.8989 138.332 79.5757 131.599 73.2747 141.196C71.2584 144.362 64.3272 155.969 60.4836 157.024ZM89.0903 97.8325C85.1837 98.4355 82.0331 102.053 78.1895 101.551C74.3459 101.048 71.1323 97.4305 65.4614 99.6414C58.9083 102.204 59.0974 114.163 67.8558 116.726C73.9678 118.535 76.0471 115.771 81.025 115.871C85.1837 115.972 89.9724 117.58 93.69 114.615C98.4158 110.947 98.6049 96.3753 89.0903 97.8325ZM97.5337 111.198C98.2898 107.53 98.4788 100.697 94.1941 98.2847C89.0273 95.4206 85.0576 98.938 80.4579 99.5409C75.102 100.244 71.2584 96.928 65.5874 98.8375C53.1744 103.058 65.7765 123.057 61.8068 139.488C60.7356 143.91 58.2782 147.578 57.081 151.748C56.1359 155.165 57.207 160.642 62.6259 157.225C66.3436 154.864 76.2362 138.433 77.4964 139.036C81.025 140.694 68.1709 160.24 77.3074 159.336C85.3097 158.532 89.5944 143.206 91.0436 138.131L97.5337 111.198Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.5237 116.022C29.7349 116.876 27.7816 118.484 21.9216 118.535C18.204 118.585 13.1002 116.223 12.8481 119.339C12.281 127.931 14.1713 133.207 9.19353 141.749C7.05118 145.367 4.53076 146.673 2.51443 149.387C-0.447057 153.457 11.6509 154.864 23.6229 136.172C27.7816 129.639 28.9158 136.976 23.1188 147.327C22.6147 148.281 18.141 154.261 21.4175 154.211C23.6859 154.211 29.0418 150.593 30.554 149.236C38.6194 141.85 53.7419 112.656 34.5237 116.022Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M38.6193 100.094C30.4279 101.45 34.8387 101.702 26.2062 100.697C24.2529 100.496 15.3054 101.852 13.4781 102.405C8.56334 103.862 8.3113 116.575 19.5902 117.429C25.3241 117.831 26.7733 116.977 31.6881 115.469C35.4688 114.364 43.3451 115.017 44.6683 113.761C45.6134 112.907 52.7966 97.732 38.6193 100.094Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.5236 116.022C53.7418 112.605 38.6823 141.849 30.4909 149.186C28.9787 150.542 23.6228 154.16 21.3544 154.16C18.0779 154.16 22.5516 148.231 23.0557 147.276C28.8527 136.925 27.7185 129.589 23.5598 136.121C11.6508 154.813 -0.510169 153.406 2.45132 149.336C4.46765 146.573 6.98806 145.317 9.13042 141.699C14.1082 133.157 12.2179 127.881 12.785 119.288C12.974 116.173 18.0779 118.534 21.8585 118.484C27.7815 118.484 29.7348 116.876 34.5236 116.022ZM38.6193 100.094C30.4279 101.45 34.8386 101.701 26.2062 100.696C24.2529 100.495 15.3054 101.852 13.4781 102.405C8.56332 103.862 8.31128 116.575 19.5901 117.429C25.3241 117.831 26.7733 116.977 31.6881 115.469C35.4688 114.364 43.345 115.017 44.6683 113.761C45.6134 112.907 52.7966 97.7319 38.6193 100.094ZM48.7009 107.178C49.9611 96.2747 34.7756 99.6915 32.2552 99.792C12.911 100.696 7.11409 97.9329 10.8947 116.876C16.3136 144.01 -4.03875 148.432 1.7582 152.15C5.28678 154.462 11.5248 151.246 14.1082 149.236C17.4478 146.673 20.3463 143.106 22.6146 139.89C30.743 128.283 19.1491 151.648 19.1491 151.748C17.7628 156.874 23.5598 155.165 26.7733 153.306C40.0055 145.618 43.4081 132.001 45.1093 120.142C45.7394 115.821 48.3229 110.394 48.7009 107.178Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M578.366 54.519C574.018 53.715 578.303 40.0979 578.681 37.7865C580.13 29.5961 570.364 43.4645 569.797 44.6202C567.024 50.2479 566.079 57.2324 565.512 63.2119C564.756 71.5028 568.977 63.2119 577.862 62.6591C588.196 62.0059 591.85 70.3471 590.59 60.2975C589.771 53.7653 590.086 46.1276 591.913 39.7462C594.875 29.3951 588.826 33.1637 584.667 38.9924C581.895 42.9118 579.185 53.6648 578.366 54.519Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M593.363 78.0348C599.097 63.8649 570.931 57.2825 564.063 72.7085C560.912 79.7935 565.386 87.8834 575.342 82.7581C582.273 79.1402 581.517 82.7078 585.486 83.4113C588.763 84.0143 592.417 80.2959 593.363 78.0348Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M593.363 78.0349C592.417 80.296 588.826 84.0144 585.486 83.3611C581.517 82.6074 582.273 79.0901 575.342 82.7079C565.449 87.8834 560.912 79.7433 564.063 72.6584C570.931 57.2825 599.096 63.865 593.363 78.0349ZM578.366 54.5189C574.018 53.7149 578.303 40.0978 578.681 37.7864C580.13 29.596 570.364 43.4644 569.797 44.6201C567.024 50.2479 566.079 57.2323 565.512 63.2118C564.756 71.5027 568.978 63.2118 577.862 62.6591C588.196 62.0058 591.85 70.347 590.59 60.2974C589.771 53.7652 590.086 46.1275 591.913 39.7461C594.875 29.395 588.826 33.1636 584.667 38.9923C581.895 42.9117 579.185 53.6647 578.366 54.5189ZM577.988 51.2026C576.98 42.2082 583.155 30.0482 588.763 29.7468C590.275 32.2089 584.478 36.5805 582.525 39.7461C579.815 44.1679 579.815 47.6852 577.988 51.2026ZM593.237 31.4049L591.409 31.3547L590.59 31.1034L590.401 29.194C585.801 28.8423 585.99 29.596 583.029 32.1084C580.13 34.5705 571.183 32.3596 566.331 49.9966C564.441 56.8806 564.756 63.1615 563.433 69.7942C562.487 74.4673 559.085 83.9641 568.599 85.2706C573.451 85.9238 574.774 83.7129 578.492 82.9592C582.147 82.2054 583.659 84.9691 587.314 84.4164C595.001 83.3109 596.072 74.819 594.371 69.8947C586.935 49.0921 596.828 39.0426 593.237 31.4049Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M577.988 51.2024C579.815 47.685 579.815 44.2179 582.525 39.7459C584.415 36.5803 590.275 32.2087 588.763 29.7466C583.155 30.0481 576.98 42.1578 577.988 51.2024Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M531.801 24.7722C519.01 34.9725 519.136 49.4439 517.939 62.9103C517.435 68.2868 521.279 61.2019 537.157 62.1063C545.79 62.6088 543.962 66.2769 546.168 47.9364C546.798 42.7609 547.428 31.0532 542.513 27.0836C536.968 22.5613 538.607 35.7765 536.59 43.2634C531.738 60.9506 526.761 48.3384 531.549 33.2138C532.684 29.8975 534.259 27.8876 531.801 24.7722Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M548.058 76.5275C550.83 69.6435 544.655 62.7596 534.952 63.2118C526.13 63.664 517.75 64.2168 515.797 72.4071C513.969 79.8941 520.459 87.8835 529.155 83.0597C537.094 78.6379 533.313 81.9542 539.93 81.9542C543.206 81.904 547.05 78.9896 548.058 76.5275Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M548.058 76.5273C547.05 79.0397 543.206 81.9038 539.867 81.9541C533.314 81.9541 537.031 78.6377 529.092 83.0595C520.397 87.8833 513.906 79.9442 515.734 72.407C517.75 64.2166 526.067 63.6639 534.889 63.2117C544.656 62.7092 550.831 69.6434 548.058 76.5273ZM531.801 24.7721C519.01 34.9724 519.136 49.4438 517.939 62.9102C517.435 68.2867 521.279 61.2017 537.157 62.1062C545.79 62.6087 543.962 66.2768 546.168 47.9363C546.798 42.7608 547.428 31.0531 542.513 27.0835C536.968 22.5612 538.607 35.7764 536.59 43.2633C531.738 60.9505 526.761 48.3383 531.549 33.2137C532.684 29.8974 534.259 27.8875 531.801 24.7721ZM531.801 50.3482C530.037 47.4841 531.612 37.4848 532.684 34.0679C533.566 31.1536 534.637 29.3446 534.952 26.2293C535.204 23.9681 535.582 21.707 538.796 21.0538C542.261 24.3701 537.976 23.5159 537.094 26.4805C536.464 28.8422 537.22 46.5796 531.801 50.3482ZM536.968 20.4005C536.968 20.4005 528.966 25.4756 528.588 25.8273C519.136 34.0679 518.569 44.6702 517.75 53.8153C517.12 60.6993 514.411 68.8897 514.222 75.0701C513.969 82.4063 520.901 87.8833 529.722 83.964C537.409 80.5471 544.025 85.7729 548.562 77.884C552.469 71.1006 545.664 69.0404 546.546 57.5839C547.113 50.097 549.003 42.7106 547.68 35.1734C547.113 32.5605 541.883 18.9434 536.968 20.4005Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M531.801 50.3483C537.22 46.5798 536.464 28.8423 537.094 26.5309C537.913 23.5663 542.261 24.4205 538.795 21.1041C535.582 21.7573 535.141 23.9683 534.952 26.2796C534.637 29.3448 533.566 31.2039 532.683 34.1183C531.612 37.5352 530.037 47.4842 531.801 50.3483Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M497.587 23.7168C491.223 31.8067 482.716 63.8145 478.621 44.4189C476.478 34.3191 482.842 25.0232 477.864 25.2242C471.311 25.5257 467.468 37.3842 467.027 41.5548C466.775 43.9667 466.586 62.8096 468.287 63.2116C469.169 63.4125 471.563 62.3573 472.635 62.0559C477.927 60.7997 488.198 62.7593 493.68 63.1111C497.209 63.3623 496.137 57.0813 496.264 54.9207C497.209 44.1174 505.148 29.646 497.587 23.7168Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M473.58 62.7092C465.074 64.2669 462.049 77.0801 465.641 81.4517C468.224 84.567 473.391 85.0695 477.235 83.3611C486.245 79.4418 480.826 82.1551 487.568 83.9641C491.223 84.969 497.398 83.6626 499.099 80.7482C501.053 77.4821 499.54 72.7086 498.217 69.4424C496.516 65.3221 494.248 63.9152 488.703 63.463C485.93 63.2117 483.536 63.6137 480.763 63.3625C478.621 63.1615 475.786 62.3073 473.58 62.7092Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M473.58 62.7092C475.785 62.3072 478.621 63.1614 480.763 63.3624C483.536 63.6137 485.93 63.2117 488.703 63.4629C494.247 63.9152 496.516 65.3221 498.217 69.4424C499.603 72.7085 501.053 77.4821 499.099 80.7482C497.335 83.7128 491.223 84.969 487.568 83.964C480.826 82.1551 486.245 79.4417 477.235 83.361C473.328 85.0695 468.161 84.567 465.641 81.4516C462.049 77.0801 465.074 64.3171 473.58 62.7092ZM497.587 23.7169C491.223 31.8068 482.717 63.8147 478.621 44.419C476.479 34.3192 482.843 25.0234 477.865 25.2244C471.312 25.5258 467.468 37.3843 467.027 41.5549C466.775 43.9668 466.586 62.8097 468.287 63.2117C469.169 63.4127 471.564 62.3575 472.635 62.056C477.928 60.7998 488.198 62.7595 493.68 63.1112C497.209 63.3624 496.138 57.0815 496.264 54.9208C497.209 44.1175 505.148 29.6462 497.587 23.7169ZM482.086 49.3433C478.495 45.8259 479.377 36.3794 480.448 32.1083C481.267 28.8924 485.93 20.4006 490.089 19.6971C497.02 23.5159 485.93 47.5344 482.086 49.3433ZM489.018 18.6921C486.623 20.3503 483.536 24.2194 481.204 24.1189C476.1 23.9179 475.722 23.8677 471.942 27.385C462.364 36.3291 467.027 50.901 465.515 63.6137C464.822 69.5429 458.836 81.7531 469.043 84.768C475.47 86.6774 477.739 83.2103 481.834 83.3108C486.119 83.4113 487.253 86.3759 494.184 84.8685C499.414 83.7128 501.242 80.6979 501.242 76.5776C501.305 68.7892 495.571 67.131 498.154 51.7552C499.477 44.0673 503.573 34.9222 501.683 27.184C500.233 21.0538 497.461 23.9179 494.562 24.7721C493.113 25.1239 493.869 20.6016 489.018 18.6921Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M482.086 49.3432C485.93 47.5343 497.02 23.5159 490.088 19.697C485.93 20.4005 481.267 28.8924 480.448 32.1082C479.314 36.3793 478.495 45.8259 482.086 49.3432Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M435.962 60.2976C437.16 60.8503 439.428 58.3379 442.389 57.9862C446.989 57.4335 448.628 60.0966 450.203 59.9459C450.518 59.0414 448.943 40.7512 448.88 37.7866C448.817 33.616 448.88 29.3952 449.069 25.2246C449.258 22.2098 450.518 16.7328 447.304 14.6726C441.948 17.2352 434.702 55.0718 435.962 60.2976Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M441.381 59.192C435.71 60.8502 432.749 68.8899 431.678 73.1107C430.922 76.1255 430.67 79.0399 433.883 81.1001C436.971 83.11 439.617 84.4667 443.713 83.7632C453.416 82.0548 454.992 78.3867 453.542 70.8998C452.723 66.5785 449.258 56.9309 441.381 59.192Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M441.382 59.1921C449.258 56.9309 452.723 66.5785 453.543 70.8998C454.992 78.3867 453.417 82.0548 443.713 83.7633C439.617 84.4667 436.908 83.11 433.883 81.1001C430.67 78.9897 430.922 76.0753 431.678 73.1107C432.749 68.8899 435.648 60.8503 441.382 59.1921ZM435.963 60.2975C437.16 60.8503 439.428 58.3379 442.39 57.9861C446.989 57.4334 448.628 60.0965 450.203 59.9458C450.518 59.0413 448.943 40.7511 448.88 37.7865C448.817 33.616 448.88 29.3951 449.069 25.2246C449.258 22.2097 450.518 16.7327 447.305 14.6725C441.949 17.2352 434.702 55.0718 435.963 60.2975ZM446.359 13.5168C437.034 24.3201 436.278 48.7405 434.072 61.5537C433.505 64.9203 432.119 67.483 431.048 70.6988C424.81 89.391 454.866 88.989 455.307 75.0201C455.496 69.5431 452.093 61.6542 451.4 55.2225C449.762 40.2989 450.455 34.8722 450.707 21.255C450.707 17.4362 451.211 14.8233 446.359 13.5168Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M423.361 60.6494C424.117 58.7902 422.857 35.425 423.109 30.7519C423.298 27.2848 425.062 19.5969 420.84 17.5368C407.986 11.2056 405.466 56.8808 406.033 59.6947C406.537 62.1066 408.553 59.7952 410.129 59.0415C417.501 55.6246 421.912 60.8504 423.361 60.6494Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M425.818 77.0302C430.04 65.0713 413.342 49.0925 404.647 67.1817C401.433 73.8646 400.74 78.4372 408.742 82.5575C415.295 85.9241 424.054 82.0048 425.818 77.0302Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M425.818 77.0301C424.054 82.0046 415.295 85.9742 408.742 82.5573C400.74 78.3868 401.433 73.8645 404.647 67.1815C413.342 49.042 430.04 65.0711 425.818 77.0301ZM423.361 60.6493C424.117 58.7901 422.857 35.4249 423.109 30.7518C423.298 27.2847 425.062 19.5968 420.84 17.5367C407.986 11.2055 405.466 56.8807 406.033 59.6946C406.537 62.1065 408.553 59.7951 410.129 59.0414C417.501 55.6245 421.911 60.8503 423.361 60.6493ZM416.115 15.979C405.529 19.0441 405.277 58.0866 404.269 63.6139C403.701 66.7795 401.874 69.5431 401.433 72.759C399.165 88.1851 426.637 89.2905 427.456 73.9649C427.645 70.1461 426.385 67.7342 425.629 64.1164C423.55 53.8658 425.251 22.6117 424.495 20.1998C423.676 17.2352 419.832 14.9238 416.115 15.979Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M419.076 16.9841L417.06 22.7123L415.421 28.0888L414.665 32.3599L414.287 37.2842L414.413 43.1632L414.287 44.4194L415.043 43.1632L415.295 38.5906L416.24 32.1589L417.753 25.1745L419.328 19.8985L420.588 16.9338L419.076 16.9841Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M389.965 1.0553C381.08 7.18553 379.379 16.5316 377.489 25.4255C376.544 29.8473 372.7 52.1071 373.519 55.1219C374.464 55.5742 377.615 52.1573 381.332 51.7553C386.877 51.2026 388.453 55.1219 390.469 54.5692C391.54 52.6095 390.091 31.7567 389.965 27.7871C389.839 22.9633 389.776 18.1395 390.154 13.3158C390.406 9.59742 392.17 4.27116 389.965 1.0553Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M381.018 52.71C373.708 54.117 370.18 64.4177 369.109 69.4928C367.407 77.6329 374.969 91.1496 392.17 78.3364C398.471 73.7136 391.477 50.7503 381.018 52.71Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M381.018 52.7099C391.477 50.7 398.409 73.7135 392.17 78.3363C374.969 91.1495 367.47 77.6328 369.109 69.4927C370.243 64.4679 373.708 54.1169 381.018 52.7099ZM389.965 1.0552C381.081 7.18543 379.379 16.5315 377.489 25.4254C376.544 29.8472 372.7 52.107 373.519 55.1218C374.465 55.5741 377.615 52.1572 381.333 51.7552C386.878 51.2025 388.453 55.1218 390.469 54.5691C391.54 52.6094 390.091 31.7566 389.965 27.787C389.839 22.9632 389.776 18.1395 390.154 13.3157C390.406 9.59733 392.17 4.27106 389.965 1.0552ZM389.335 0C372.259 11.7077 375.599 43.7156 368.857 65.7744C366.903 72.2061 367.281 81.8537 376.985 84.3158C384.168 86.1247 395.132 80.095 395.825 73.8643C396.392 68.538 392.738 59.7949 392.297 49.5946C391.792 38.942 390.595 20.6016 391.918 10.2003C392.36 6.13023 393.683 2.71338 389.335 0Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M344.535 57.4835L344.661 59.0914L348 56.78C356.129 54.6194 356.948 58.9407 358.901 58.9407C359.342 57.9357 358.082 39.3943 358.082 36.3291C358.145 32.4098 358.334 28.3398 359.09 24.4707C359.657 21.5563 362.052 16.833 360.351 14.2201C349.45 11.6575 344.976 52.3079 344.535 57.4835Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M340.376 80.0447C341.51 86.0745 357.893 84.0143 360.413 81.2004C362.745 78.5373 360.981 65.4226 359.216 62.3073C351.025 47.5847 338.108 67.6335 340.376 80.0447Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M340.376 80.0448C338.108 67.6336 351.025 47.5847 359.216 62.257C360.981 65.4227 362.745 78.5373 360.414 81.1502C357.893 84.0143 341.448 86.0745 340.376 80.0448ZM348.064 56.78C356.192 54.6194 357.011 58.9407 358.964 58.9407C359.405 57.9357 358.145 39.3943 358.145 36.3292C358.208 32.4099 358.397 28.3398 359.153 24.4707C359.721 21.5563 362.115 16.833 360.414 14.2202C349.45 11.7078 344.976 52.3582 344.598 57.5338C343.779 59.2422 343.59 58.9909 344.598 60.4984L348.064 56.78ZM359.91 12.6625C348.757 14.5719 345.48 39.2938 344.409 46.7807C343.401 54.0164 343.086 59.9457 340.88 67.0808C339.368 71.9549 335.903 83.1601 344.724 84.6173C353.861 86.1247 362.934 84.5168 362.871 75.9747C362.808 68.8898 360.918 61.6038 360.351 54.3681C359.784 46.9315 359.279 39.1933 359.721 31.7566C360.351 21.9081 364.761 15.4261 359.91 12.6625Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M344.535 57.4835C343.716 59.1919 343.527 58.9407 344.535 60.4481L348.001 56.78L344.661 59.0914L344.535 57.4835Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M329.286 52.4586L332.248 54.167L332.311 51.7049C332.311 45.0219 331.239 38.138 331.113 31.3545C331.05 27.0835 332.815 14.0693 329.853 11.4062C319.457 9.24551 312.651 51.2024 314.29 55.4735C316.495 55.775 319.394 47.9866 329.286 52.4586Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M335.902 78.0348C337.603 72.9598 335.398 60.5988 331.68 56.0765C325.694 48.7403 316.999 53.1119 313.722 59.5436C312.084 62.7595 308.052 78.8388 310.131 81.3512C311.958 83.4616 321.788 82.6074 324.938 82.5571C329.916 82.4566 334.39 82.5571 335.902 78.0348Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M335.902 78.0347C334.39 82.557 329.916 82.4565 325.001 82.557C321.851 82.6073 312.021 83.4615 310.194 81.3511C308.115 78.8889 312.147 62.7594 313.786 59.5435C317.062 53.1118 325.758 48.7403 331.744 56.0764C335.398 60.5987 337.603 72.9597 335.902 78.0347ZM332.311 51.6546C332.311 44.9717 331.239 38.0877 331.113 31.3043C331.05 27.0332 332.815 14.019 329.853 11.3559C319.456 9.19525 312.651 51.1522 314.29 55.4232C316.495 55.775 319.456 47.9865 329.286 52.4084C330.357 53.3128 331.681 54.971 332.878 55.172L332.311 51.6546ZM327.144 10.3007C317.818 13.2653 314.227 41.3538 313.407 48.1875C312.462 56.0262 311.013 62.1062 309.501 69.7438C307.611 79.3914 305.405 84.0645 319.961 83.8132C331.113 83.6122 337.54 84.165 337.666 73.4622C337.793 65.6738 334.957 59.2421 334.012 51.6546C333.067 43.9667 332.5 35.9773 332.5 28.2392C332.5 25.6765 335.335 7.68782 327.144 10.3007Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M329.286 52.4587C330.357 53.3631 331.681 55.0213 332.878 55.2223L332.311 51.705L332.248 54.1671L329.286 52.4587Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M278.374 52.3081L278.437 54.0165L281.588 52.4086C291.48 47.9365 294.253 55.7249 296.584 55.4234C297.466 53.1623 294.001 33.9676 293.181 30.4C292.299 26.4304 287.952 10.8034 281.336 11.2054C277.807 13.4163 279.823 26.7822 279.76 30.7518C279.634 38.0879 278.689 45.1226 278.374 52.3081Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M274.782 77.03C275.664 81.703 278.752 82.3562 284.045 82.507C287.069 82.6075 297.655 83.1099 299.734 82.0547C303.641 80.1453 299.041 64.0158 297.592 60.6492C295.072 54.5692 286.817 48.2882 279.886 55.1722C275.349 59.795 273.711 71.4022 274.782 77.03Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M274.782 77.0298C273.711 71.4523 275.349 59.7948 279.949 55.172C286.817 48.2881 295.072 54.5188 297.655 60.6491C299.041 64.0157 303.704 80.1954 299.797 82.0546C297.655 83.1098 287.069 82.5571 284.108 82.5069C278.752 82.3561 275.664 81.6526 274.782 77.0298ZM281.587 52.4084C291.48 47.9364 294.252 55.7248 296.584 55.4233C297.466 53.1621 294 33.9675 293.181 30.3999C292.299 26.4303 287.951 10.8032 281.335 11.2052C277.807 13.4161 279.823 26.782 279.76 30.7516C279.634 38.0375 278.626 45.0722 278.374 52.3079L277.681 55.1218L281.587 52.4084ZM280.201 10.15C276.421 15.7778 278.5 25.7268 278.311 32.3093C278.059 39.746 277.618 48.0369 276.358 55.373C273.081 74.015 265.331 85.773 295.954 83.763C306.287 83.0596 302.129 72.5075 300.743 66.6788C298.915 58.9406 298.096 52.8104 297.088 45.022C296.017 36.6306 293.811 11.4565 280.201 10.15Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M281.588 52.4085L278.437 54.0164L278.374 52.308L277.744 55.1219L281.588 52.4085Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M261.361 56.5789C265.582 57.6843 263.944 58.0863 266.15 59.0913L266.339 57.4833C265.961 52.2576 261.487 11.7076 250.523 14.1698C248.885 16.6821 251.216 21.5562 251.783 24.4203C253.737 35.0728 251.405 57.6341 251.909 58.8903C253.737 58.9405 254.997 54.971 261.361 56.5789Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M249.578 80.0447C251.027 84.3158 267.851 85.4212 269.93 81.4014C271.757 77.9343 269.111 66.8798 267.095 63.3625C263.881 57.8352 257.58 54.2174 252.602 61.0008C250.019 64.4679 248.317 76.2259 249.578 80.0447Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M249.578 80.0447C248.318 76.2258 250.019 64.4678 252.603 61.0007C257.58 54.2173 263.881 57.8351 267.095 63.3624C269.111 66.8797 271.758 77.9343 269.93 81.4014C267.851 85.4212 251.027 84.3157 249.578 80.0447ZM266.339 57.4834C265.961 52.2576 261.487 11.7077 250.523 14.1698C248.885 16.6822 251.216 21.5562 251.783 24.4204C253.737 35.0729 251.405 57.6341 251.909 58.8903C253.737 58.9406 254.997 54.971 261.361 56.5287C266.465 58.8401 263.44 57.9356 266.339 60.3978C267.347 58.9406 267.158 59.1918 266.339 57.4834ZM249.767 12.4111C247.499 18.1394 250.901 26.3298 251.216 32.8117C252.098 50.7502 247.688 69.2916 248.066 76.9795C248.255 80.5471 249.326 82.9088 253.863 84.0645C257.139 84.9187 264.574 85.2704 267.788 84.2655C274.782 82.1048 271.191 70.6483 269.804 66.2768C267.599 59.3426 267.347 52.5089 266.339 45.4239C264.89 35.8769 263.188 16.1295 249.767 12.4111Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M266.339 57.4833L266.15 59.0912C263.944 58.036 265.583 57.6341 261.361 56.5789C266.465 58.8903 263.44 57.9858 266.339 60.4479C267.347 58.9405 267.158 59.1917 266.339 57.4833Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M220.53 1.2561C219.144 5.8789 220.467 9.14501 220.782 13.7678C221.097 18.5916 221.034 23.4154 220.908 28.2894C220.782 32.1082 219.396 52.6596 220.404 54.569C222.295 55.0715 223.933 51.4536 229.037 51.7049C233.258 51.9059 236.346 55.574 237.354 55.0715C238.173 52.0064 234.266 29.4451 233.321 24.9228C231.809 17.9384 229.289 4.7232 220.53 1.2561Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M226.831 52.8103C219.207 54.77 217.001 65.322 216.434 70.6986C215.867 75.3716 216.119 76.9293 220.845 79.6929C224.122 81.6023 227.839 84.165 232.061 83.7127C250.271 81.9038 239.118 49.695 226.831 52.8103Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M226.831 52.8104C239.118 49.695 250.271 81.9039 232.061 83.7128C227.777 84.1148 224.122 81.6024 220.845 79.693C216.12 76.9293 215.931 75.3717 216.435 70.6986C217.065 65.3221 219.207 54.7701 226.831 52.8104ZM237.354 55.0715C238.173 52.0064 234.267 29.4452 233.321 24.9229C231.809 17.9384 229.289 4.72326 220.53 1.20592C219.144 5.82871 220.467 9.09482 220.782 13.7176C221.097 18.5414 221.034 23.3652 220.908 28.2392C220.782 32.0581 219.396 52.6094 220.404 54.5188C222.295 55.0213 223.933 51.4035 229.037 51.6547C233.258 51.9059 236.346 55.574 237.354 55.0715ZM219.837 0.100464C217.632 6.0297 219.711 18.2399 219.585 25.2746C219.459 34.1685 218.955 43.0623 218.514 51.9562C218.199 58.6894 213.851 69.9449 215.426 75.4721C218.766 87.2804 250.586 90.4962 241.513 64.1664C235.401 46.2782 238.362 5.77846 219.837 0.100464Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M190.474 17.3356C185.748 19.1947 187.45 26.4304 187.702 29.797C188.017 35.073 187.954 40.55 187.765 45.8763C186.82 73.3116 186.568 53.0617 200.241 58.9407C204.021 60.5989 205.408 64.267 204.652 52.911C204.336 47.9867 203.706 43.1127 202.887 38.1884C202.194 34.4198 199.296 13.8685 190.474 17.3356Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M184.992 76.5275C186.126 81.2508 195.074 85.9741 201.375 82.9592C209.818 78.9896 209.629 74.8693 206.605 67.8849C198.729 49.8459 181.653 62.8098 184.992 76.5275Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M184.992 76.5275C181.653 62.8099 198.791 49.8459 206.605 67.9351C209.629 74.9196 209.818 79.0399 201.375 83.0095C195.074 85.9741 186.126 81.2508 184.992 76.5275ZM190.474 17.3356C185.748 19.1948 187.45 26.4305 187.702 29.7971C188.017 35.0731 187.954 40.5501 187.765 45.8763C186.82 73.3116 186.567 53.0618 200.241 58.9408C204.021 60.5989 205.408 64.267 204.651 52.911C204.336 47.9868 203.706 43.1127 202.887 38.1884C202.194 34.4199 199.296 13.8685 190.474 17.3356ZM189.907 15.9789C183.984 17.7376 186.441 30.9025 186.504 34.8218C186.694 42.359 186.252 49.9967 185.874 57.5841C185.559 63.5636 182.157 72.3569 184.047 77.3817C186 82.6075 194.318 86.6776 201.123 84.3159C209.314 81.4518 210.826 75.824 208.495 69.3923C205.534 61.2019 206.416 56.8304 205.597 48.5897C205.093 43.0625 202.572 12.2606 189.907 15.9789Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M190.474 17.3354L191.734 22.109L193.246 27.988L193.624 34.9222L194.002 40.3489L194.381 43.6653L195.074 40.6002L195.137 38.0878L194.822 33.3142L194.759 27.787L193.309 21.6568L191.608 16.3807L190.474 17.3354Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M160.544 59.0411L160.733 60.046L164.262 58.4884C171.13 56.3277 172.39 60.5485 174.911 60.6993C176.36 56.9809 168.925 14.7225 162.939 14.9738C160.481 17.2852 162.056 32.862 162.056 36.8818C161.993 44.3185 161.237 51.5542 160.544 59.0411Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M164.199 59.7948L163.569 60.2973L162.876 60.7495L162.309 61.252L161.678 61.7042C158.024 65.3723 155.881 73.5627 157.457 78.0347C159.158 82.959 169.366 85.4714 174.596 82.557C182.094 78.3865 180.203 74.7686 177.746 68.3369C175.919 63.9654 171.382 56.6292 164.199 59.7948Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M161.616 61.7545L159.851 60.4481L160.608 59.0411C161.301 51.5542 161.994 44.3185 162.12 36.8316C162.183 32.8118 160.608 17.235 163.002 14.9236C168.988 14.7226 176.423 56.981 174.974 60.6491C172.517 60.5486 171.193 56.2775 164.325 58.4382L161.616 60.2973L162.309 61.3023C161.742 60.1968 161.49 60.3476 162.876 60.7998C162.309 59.6441 162.12 59.8451 163.569 60.3476C163.128 59.3426 162.939 59.4934 164.199 59.8451C171.445 56.6795 175.919 64.0157 177.62 68.4375C180.141 74.8692 181.968 78.487 174.47 82.6576C169.24 85.572 159.032 83.0093 157.331 78.1353C155.756 73.613 157.961 65.4226 161.616 61.7545ZM161.805 13.8181C159.032 19.8981 160.734 29.1437 160.734 35.8266C160.671 43.1628 160.166 50.6497 159.284 57.9859C158.528 64.2669 154.496 71.7036 156.008 77.6328C157.583 83.6625 167.728 86.7277 174.407 84.1148C182.976 80.6979 181.527 74.5174 179.07 68.3872C176.045 60.9003 174.659 41.1027 171.319 28.39C169.933 23.6667 169.051 14.5216 161.805 13.8181Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M164.262 58.4382L160.733 59.9959L160.544 58.991L159.788 60.3979L161.553 61.7043L162.183 61.2521L162.75 60.7496L163.443 60.2974L164.073 59.7949C162.813 59.4934 163.002 59.2924 163.443 60.2974C161.994 59.7949 162.183 59.5939 162.75 60.7496C161.364 60.2472 161.553 60.1467 162.183 61.2521L161.49 60.2472L164.262 58.4382Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M111.963 23.8175C108.435 31.1537 110.766 37.8869 112.782 45.2733C118.201 65.1212 106.544 63.7142 132.127 61.8551C134.647 61.6541 136.285 61.5033 138.743 62.1566C139.751 62.4581 142.082 63.664 142.964 63.0108C144.162 62.1566 144.036 42.6604 143.657 40.148C143.153 36.7814 138.365 23.9682 132.442 25.2244C128.346 26.1289 134.836 35.2238 131.938 45.625C126.96 63.5133 119.524 27.2846 111.963 23.8175Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M133.135 62.9607C120.974 64.9706 114.988 60.0463 111.396 73.0102C108.75 82.5573 117.508 87.6826 126.708 82.2056C130.551 79.8942 132.379 84.2657 137.861 84.4165C152.542 84.8184 147.879 60.4985 133.135 62.9607Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M133.135 62.9607C147.879 60.4985 152.542 84.8185 137.923 84.4165C132.379 84.2657 130.614 79.8942 126.771 82.2056C117.571 87.6826 108.813 82.5573 111.459 73.0102C114.925 60.0463 120.974 64.9706 133.135 62.9607ZM111.963 23.8176C108.435 31.1538 110.766 37.887 112.782 45.2734C118.201 65.1213 106.544 63.7144 132.127 61.8552C134.647 61.6542 136.285 61.5035 138.743 62.1567C139.751 62.4582 142.082 63.6641 142.964 63.0109C144.161 62.1567 144.035 42.6605 143.657 40.1482C143.153 36.7816 138.365 23.9684 132.442 25.2246C128.346 26.129 134.836 35.2239 131.937 45.6252C126.96 63.5134 119.524 27.2847 111.963 23.8176ZM128.787 49.3435C124.502 47.3838 112.845 19.4461 121.289 19.6973C127.023 19.8983 136.348 41.9068 128.787 49.3435ZM119.713 18.7928C118.012 21.657 118.075 25.727 115.177 24.1191C110.199 21.4057 109.065 26.4305 108.876 29.3951C107.931 41.0024 116.752 53.8156 112.089 67.081C110.262 72.4072 105.221 84.2657 119.209 85.1702C124.628 85.5219 126.96 82.4066 131.118 83.5622C135.592 84.8184 139.121 86.5771 143.909 83.8637C151.408 79.5424 145.611 68.6889 145.17 61.2522C144.414 48.9415 148.257 34.5204 137.419 26.0285C132.19 21.9585 131.622 24.9231 128.409 23.9684C125.636 23.2147 126.771 20.049 119.713 18.7928Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M128.787 49.3434C136.285 41.9068 126.96 19.8982 121.289 19.6972C112.845 19.4963 124.565 47.3838 128.787 49.3434Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M77.7483 24.5712C77.8113 32.0078 81.2769 37.5351 81.0879 45.826C80.7728 59.3929 75.1649 47.2832 73.9047 42.0574C73.3376 39.6455 73.5896 20.6016 67.4776 27.9378C63.1299 33.1635 64.0751 43.7658 64.8942 49.7956C67.1626 67.2818 64.7052 62.056 75.3539 62.1565C92.8708 62.3073 93.7529 68.8897 92.8078 61.7043C91.1695 50.1975 92.1777 31.5054 77.7483 24.5712Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M62.4999 75.5224C63.067 78.0851 66.7846 81.4014 69.9982 81.8537C75.4801 82.6074 74.6609 78.5876 81.088 82.6074C89.2793 87.7327 96.4625 81.2507 95.3913 73.7135C94.0051 64.1162 86.6329 64.3172 77.0553 63.3122C67.6038 62.3575 60.8617 67.8848 62.4999 75.5224Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M62.4999 75.5225C60.8616 67.8849 67.6037 62.3576 76.9923 63.3123C86.5699 64.267 93.9421 64.1163 95.3283 73.7136C96.3995 81.2508 89.2163 87.7327 81.0249 82.6075C74.5979 78.5876 75.48 82.6075 69.9351 81.8537C66.7846 81.4015 63.067 78.0852 62.4999 75.5225ZM67.4777 27.9379C63.13 33.1636 64.0752 43.7659 64.8943 49.7957C67.1627 67.2819 64.7053 62.0561 75.354 62.1566C92.8709 62.3074 93.753 68.8898 92.8079 61.7044C91.2326 50.1976 92.1778 31.5055 77.8114 24.5713C77.8744 32.0079 81.34 37.5352 81.151 45.8261C80.8359 59.393 75.228 47.2833 73.9678 42.0575C73.3377 39.5954 73.5897 20.6017 67.4777 27.9379ZM73.6527 26.1792C72.8336 24.2195 68.1078 23.2648 72.3925 21.1544C75.9211 21.8579 75.6691 24.923 75.9841 27.2344C76.2992 29.596 82.4112 44.8714 79.0086 50.3484C75.8581 48.1375 74.4719 38.6406 74.2198 35.3243C74.0938 33.6159 74.0938 27.2846 73.6527 26.1792ZM70.9433 20.1997C66.9736 27.4354 63.6341 29.1941 62.941 37.9372C62.3109 45.8261 64.7053 53.313 64.5792 60.9507C64.4532 67.6336 57.6481 73.6131 63.8861 79.7433C66.8476 82.708 69.6201 82.9592 74.0938 82.6075C80.4579 82.105 79.4497 84.0646 84.3645 85.0194C94.4462 86.979 97.5967 78.8891 96.4625 72.4072C95.1393 64.7192 93.564 58.5388 92.9969 50.5996C92.1148 39.2939 87.0109 23.2146 70.9433 20.1997Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M79.0719 50.3483C82.4744 44.8713 76.2994 29.5959 76.0474 27.2343C75.7323 24.9229 75.9213 21.8578 72.4558 21.1543C68.1081 23.2647 72.8968 24.2194 73.716 26.1791C74.157 27.2845 74.2201 33.6157 74.3461 35.3242C74.5351 38.6908 75.9213 48.1876 79.0719 50.3483Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.637 32.8118C18.2041 37.8868 19.8423 42.5096 20.4094 47.6349C21.0395 53.6144 19.5273 59.0411 19.9053 64.0156C20.2204 68.0355 29.1048 58.6391 40.8248 64.9704C42.022 65.6236 44.8574 68.2365 45.4245 66.327C47.0628 60.1968 41.959 44.218 37.2332 38.6908C26.3324 25.7771 38.8084 53.3631 32.5704 54.5188C30.4281 52.4084 29.6089 35.1734 17.637 32.8118Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3565 63.7644C10.3273 66.729 17.0064 84.1147 24.9457 83.4615C28.6633 83.16 28.6003 79.0899 35.1534 82.5068C45.1721 87.7326 49.8978 80.3461 46.9363 73.0602C44.731 67.5329 36.9807 62.5584 29.3565 63.7644Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3568 63.7644C36.9181 62.5584 44.7314 67.5832 46.9367 73.1105C49.8982 80.3964 45.1724 87.7828 35.1538 82.5571C28.6007 79.1402 28.6637 83.2103 24.9461 83.5118C17.0068 84.1147 10.3277 66.729 29.3568 63.7644ZM19.9053 64.0156C20.2203 68.0355 29.1048 58.6391 40.8247 64.9703C42.0219 65.6236 44.8574 68.2364 45.4245 66.327C47.0627 60.1968 41.9589 44.218 37.2331 38.6907C26.3323 25.7771 38.8084 53.3631 32.5704 54.5188C30.491 52.4084 29.6089 35.1734 17.6999 32.8118C18.267 37.8868 19.9053 42.5096 20.4724 47.6349C21.1025 53.6143 19.5272 59.0411 19.9053 64.0156ZM30.491 37.9873C32.6334 42.2081 33.4525 46.6299 32.8854 51.2024C31.0581 47.6851 31.0581 44.218 28.3487 39.7459C26.2693 36.2789 20.9134 32.5103 21.9216 29.6964C25.2612 29.8471 29.3568 35.6759 30.491 37.9873ZM20.5354 29.1437L20.3463 31.2038L19.4642 31.3546L17.6369 31.4048C14.1713 38.8415 23.8119 49.695 16.6287 69.8444C14.8644 74.8189 15.8726 83.2605 23.6229 84.366C27.2145 84.8685 28.8527 82.1551 32.4443 82.8586C36.0989 83.562 37.4852 85.8232 42.274 85.1699C51.7885 83.8635 48.3229 74.3667 47.4408 69.6936C45.6765 60.7998 46.4326 42.8613 35.4058 35.0227C32.7594 33.1635 30.68 34.5202 28.3487 32.5605C25.3242 29.9476 25.5132 28.7919 20.5354 29.1437Z" fill="#989189"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8855 51.2026C33.4526 46.63 32.6335 42.2585 30.4911 37.9874C29.3569 35.676 25.2612 29.8473 21.9217 29.6965C20.9135 32.5104 26.2694 36.279 28.3488 39.7461C31.0582 44.2181 31.0582 47.635 32.8855 51.2026Z" fill="white"/>
        </svg>
      </figure>
      <p class="text-center f-16 mtop12px">Total dinti selectati: <br> {{ data.dinti.join(', ') }} </p>
    </div>

    <div class="select-medic">
      <h4>selectati medicul:</h4>
      <multiselect
        v-model="data.medic"
        :multiple="false"
        :options="medici"
        @input="handleMedic"
        placeholder="selectati medicul"
        track-by="name"
      />
    </div>

    <div class="observatii">
      <label for="observatii">
        observatii:
      </label>
      <textarea cols="30" rows="4"
                id="observatii"
                v-model="data.observatii"
                placeholder="Observatii" />
    </div>

    <footer class="status flex flex-between">
      <div class="status flex align-center">
        <span class="f-16">status:</span>
        <label for="lucru"
               :class="{'selected-status-lucru': data.status === 'lucru'}">
          <input id="lucru"
                 name="status"
                 @change="handleStatus('lucru')"
                 type="radio"
                 checked>
          <b>In lucru</b>
        </label>
        <label for="finalizat"
               :class="{'selected-status-finalizat': data.status === 'finalizat'}">
          <input id="finalizat"
                 @change="handleStatus('finalizat')"
                 name="status"
                 type="radio">
          <b>Finalizat</b>
        </label>
      </div>

      <div class="flex align-center">
        <button @click="handleClose">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z" fill="#525252"/>
          </svg>
        </button>
        <button :disabled="!data.tratament || !data.medic"
                @click="handleSubmit">
          <i>
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M33.4319 10.4213C33.611 10.2385 33.8248 10.0933 34.0608 9.99419C34.2968 9.89506 34.5501 9.84399 34.8061 9.84399C35.062 9.84399 35.3154 9.89506 35.5513 9.99419C35.7873 10.0933 36.0011 10.2385 36.1803 10.4213C36.931 11.1799 36.9415 12.4058 36.2065 13.1776L20.6849 31.5263C20.5087 31.7198 20.2948 31.8754 20.0564 31.9834C19.818 32.0914 19.5601 32.1497 19.2984 32.1546C19.0367 32.1595 18.7768 32.111 18.5345 32.012C18.2922 31.913 18.0726 31.7656 17.8893 31.5788L8.44451 22.0081C8.08026 21.6366 7.87622 21.1371 7.87622 20.6168C7.87622 20.0966 8.08026 19.597 8.44451 19.2256C8.62366 19.0428 8.83747 18.8976 9.07343 18.7984C9.30939 18.6993 9.56276 18.6482 9.8187 18.6482C10.0746 18.6482 10.328 18.6993 10.564 18.7984C10.7999 18.8976 11.0137 19.0428 11.1929 19.2256L19.2044 27.3447L33.3794 10.4791C33.3957 10.4588 33.4132 10.4395 33.4319 10.4213Z" fill="white"/>
            </svg>
          </i>
        </button>
      </div>
    </footer>
	</div>
</template>

<script>
	export default {
		name: 'Tratament',
    props: {
      tratament: {
        type: Object
      },
      etapaId: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        data: {
          tratament: '',
          dinti: [],
          medic: '',
          observatii: '',
          status: 'lucru'
        }
      }
    },
    computed: {
      tratamente () {
        return this.$store.state.tratamente
      },
      medici () {
        return this.$store.state.medici
      }
    },
    methods: {
      handleMedic (medic) {
        this.data.medic = medic
      },
      handleStatus (status) {
        this.data.status = status
      },
      handleSubmit () {
        this.$store.commit('UPDATE_TRATAMENT', {
          etapaId: this.etapaId,
          tratament: {...this.data, ...this.tratament}
        })
        // this.$store.dispatch('update_plan')
      },
      handleClose () {
        this.$store.commit('CLOSE_TRATAMENT', this.etapaId)
      }
    }
	}
</script>

<style lang="less">
  @import "../assets/styles/var";
	.tratament-etapa {
    padding: 24px;
    margin-bottom: 48px;
    background: linear-gradient(90deg, #E6E4E4 0%, rgba(230, 228, 228, 0.1) 100%);
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    h4, label {
      font-size: 1.6rem;
      margin-bottom: 8px;
      color: #2a2a2a;
    }
    h4 {
      font-weight: 400;
    }
    label {
      display: block;
    }
    .multiselect__tags input {
      border: none!important;
    }
    span.multiselect__placeholder {
      transform: translate(4px, 1px);
    }
    figure svg {
      width: 100%;
    }
    label[for="cost"] {
      margin-top: 8px;
      margin-bottom: 24px;
      input {
        width: 80px;
        padding-left: 8px;
        border-radius: 4px;
      }
    }
    .select-medic {
      margin-bottom: 24px;
    }
    textarea {
      width: 100%;
      border: 1px solid #C4C4C4;
      border-radius: 4px;
      resize: none;
      font-size: 1.6rem;
      padding-left: 12px;
    }
    footer {
      margin-top: 16px;
      .status {
        label {
          margin-left: 12px;
          margin-bottom: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            left: 4px;
            top: 4px;
            display: inline-block;
          }
          &:before {
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
          }
          &:last-child b {
            color: @pt-green;
          }
          &:last-child:before {
            border: 2px solid @pt-green;
          }
          &:not(:last-child) b {
            color: @pt-blue;
          }
          &:not(:last-child):before {
            border: 2px solid @pt-blue;
          }
        }
        input {
          width: 0.1px;
          height: 0;
          visibility: hidden;
        }
        .selected-status-lucru:after {
          background-color: @pt-blue;
        }
        .selected-status-finalizat:after {
          background-color: @pt-green;
        }
      }
      button {
        cursor: pointer;
        border: none;
        padding: 0 36px;
        border-radius: 4px;
        height: 40px;
        transition: background-color 0.1s ease-in-out;
        &:first-child {
          margin-right: 16px;
          background-color: lightgray;
          &:hover {
            background-color: darken(lightgray, 20%, relative);
          }
        }
        &:last-child {
          background-color: @pt-green;
          &:not([disabled]):hover {
            background-color: darken(@pt-green, 20%, relative);
          }
          &[disabled] {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }
  }
</style>
